import BaseWithErrorBoundary from '@/components/base/baseWithErrorBoundary';
import usePageRefreshOnVisible from '@/hooks/usePageRefreshOnVisible';
import FinanceList from '@/pages/public/market/components/FinanceList';
import { useState } from 'react';

/**
 *  ieo 市场
 */
export default BaseWithErrorBoundary(({}: any) => {
  // 回到页面刷新
  const pageKey = usePageRefreshOnVisible();
  // 用于做外部加载
  const [loadingDisplay, setLoadingDisplay] = useState(false);
  return (
    <div className="px-4 min-h-[40vh]" key={pageKey}>
      {/*Ieo*/}
      <FinanceList setLoadingDisplay={setLoadingDisplay} />
    </div>
  );
});
